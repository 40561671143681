export const hamburgerMenu = () => {
  const hamburgerButton = document.querySelector('.js-hamburgerButton');
  const body = document.querySelector('body');

  if(!hamburgerButton) return;

  hamburgerButton.addEventListener('click', () => {
    const hambState = body.dataset.hambState;
    hambState === 'close' ? 
    body.dataset.hambState = 'open' : 
    body.dataset.hambState = 'close';
  });

  window.addEventListener('resize', () => {
    if(window.innerWidth > 768) {
      body.dataset.hambState = 'close';
    }
  });
};